/** @jsx jsx */
import React from 'react'
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'

import PageHeader from './PageHeader'
import WeTransferLogo from './WeTransferLogo'

import localCoverArt from '../images/2021.jpeg'

interface Props {
  data: {
    title: string
    playlists: Array<{
      id: string
      title: string
      image?: string
      localImage?: string
    }>
  }
}

export default function PlaylistsOverview({
  data: { title, playlists },
}: Props) {
  return (
    <div>
      <PageHeader
        title={title}
        spaceBetween
        padding
        extra={
          <div sx={{ float: 'right' }}>
            <WeTransferLogo />
          </div>
        }
      />
      <div
        sx={{
          display: 'flex',
          flexFlow: 'row wrap',
        }}
      >
        {playlists.map((playlist) => (
          <Link
            to={`/playlist/${playlist.id}`}
            key={playlist.id}
            sx={{
              textDecoration: 'none',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              padding: '15px',
              border: '1px solid #000',
              margin: '-1px 0 0 -1px',
              '&:hover': {
                '.playlist-overview': {
                  opacity: 1,
                  pointerEvents: 'all',
                },
              },
              '&:focus': {
                '.playlist-overview': {
                  opacity: 1,
                  pointerEvents: 'all',
                },
              },
            }}
          >
            <img
              src={playlist?.image || localCoverArt}
              sx={{
                width: '280px',
                height: '280px',
                objectFit: 'cover',
                marginBottom: '15px',
                '@media only screen and (max-width: 578px)': {
                  width: '80vw',
                  height: '80vw',
                }
              }}
            />
            <span
              sx={{
                fontSize: '18px',
                fontStyle: 'normal',
                color: 'black'
              }}
            >
              {playlist.title}
            </span>
            <div
              className="playlist-overview"
              sx={{
                pointerEvents: 'none',
                position: 'absolute',

                width: '280px',
                height: '280px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                opacity: '0',
                transition: 'opacity 0.25s ease-out',
                '@media only screen and (max-width: 578px)': {
                  width: '80vw',
                  height: '80vw',
                }
              }}
            >
              <button
                sx={{
                  border: '1px solid #000',
                  backgroundColor: '#DCDCDC',
                  padding: '10px 40px',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  cursor: 'pointer',
                }}
              >
                Play
              </button>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}
