import React from 'react'

import { Box } from 'theme-ui'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import PlaylistsOverview from '../components/PlaylistsOverview'
import playlists from '../utils/spotifyPlaylists'

const data = {
  title: 'Discover our playlists',
  playlists,
}

export default function PlaylistsPage() {
  return (
    <Layout>
      <SEO title="Playlists" />
      <PlaylistsOverview data={data} />
    </Layout>
  )
}
